import type { UpdatedIssue } from './Table/types'
import type { Issue } from '~/api/types'
import type { ShortIssueWithId } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import type { DataLayerLocationsEnum } from '#imports'

export type IssueBuyLocations = DataLayerLocationsEnum.IssuesList | DataLayerLocationsEnum.IssueDetails | DataLayerLocationsEnum.Floating

export enum IssueBuyUrlTypeEnum {
  JAMES = 'james',
  AFFILIATE = 'affiliate',
}

export type IssueBuyUrl = {
  orderUrl: string
  urlType: IssueBuyUrlTypeEnum
}

export type IssueBuyEmit = {
  issue: UpdatedIssue | Issue | ShortIssueWithId
  url: IssueBuyUrl
}
